import Vue from 'vue'


import '@/styles/basic.css'
import App from './App.vue'
import ajaxApi from './api/request';
import wx from 'weixin-js-sdk'
import router from './router'
import store from './store'


Vue.config.productionTip = false

Vue.prototype.$ajaxApi = ajaxApi;
Vue.prototype.$wx = wx;


router.beforeEach((to, from, next) => {
  // document.title = '详情'
  next();
})

import _ from 'lodash';

Vue.prototype._ = _;


import {Image as VanImage} from 'vant';
import {Toast, Icon, Field, Popup, Form,button} from 'vant';

Vue.use(Icon);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(button);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
