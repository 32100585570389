import axios from 'axios'
import {getQueryVariable} from "@/libs/util";

let baseUrl = ''
let baseYun = ''
// let baseUrlTwo = ''
// let baseUrlThree = '';
// let fullUrl = ''
let token = getQueryVariable('token');
// token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6ImIxMjMyYjcwLWE5NjMtMTFlYS05ZDVjLTIzY2QwYzY1MmQ1NyIsIm5hbWUiOiJsaW1lMSJ9.eY84kNA8kzBiDEoI5-rhc_59gDWQidOWW7Qz14W8YRE"
// token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6ImIxODA1YmIwLTlhNzItMTFlYS1iMmMxLWIxNDI2NTk0YTczMyIsIm5hbWUiOiLpnZLmn6AxIn0.uDo4b97s8V38i048R9tK3mC0K_7ISfacCwldvLWaHXA"

let headers = {
  'x-client-tag': 'MP',
  'x-token': token,
}
baseUrl = '/api2/v1/app';

// baseUrl = 'https://yunicu-api.yunicu.com/api2/v1/app';
baseUrl = 'https://circle-h5.yunicu.com/api2/v1/app';
baseYun = 'https://yun-new-admin-api.yunicu.com';



const communityApi = {
  /**
   *获取社交帖子详情
   */
  communityDetail(id) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/post/' + id,
      method: 'GET',
    })
  },
  /**
   *帖子详情-点赞列表
   */
  getPraiseList(query) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/post-praise-list',
      method: 'GET',
      params:query
    })
  },
  /**
   *点赞列表
   */
  addPraise(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/post-praise-add',
      method: 'POST',
      data:data
    })
  },

  /**
   *发表评论
   */
  addComment(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/post-comment-add',
      method: 'POST',
      data:data
    })
  },
  /**
   *发表评论
   */
  updateMyInfo(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/update_my_info',
      method: 'PUT',
      data:data
    })
  },
  /**
   *发表评论
   */
  replyComment(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/post-comment-reply',
      method: 'POST',
      data:data
    })
  },

  /**
   *帖子详情-评论列表
   */
  getCommentList(query) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/post-comment-list',
      method: 'GET',
      params:query
    })
  },
  /**
   *获取个人信息
   */
  getMyInfo(query) {
    return axios({
      headers: headers,
      url: baseUrl + '/community/my_info',
      method: 'GET',
      params:query
    })
  },
  /**
   *APP帖子点赞信息
   */
  praiseInfo(memberId,contentId,contentType) {
    return axios({
      url: baseYun + '/v3/praiseInfo/'+memberId+'/'+contentId+'/'+contentType,
      method: 'GET',
    })
  },
  /**
   *热门推荐-评论列表
   */
  hotList() {
    return axios({
      url: baseYun + '/v3/app/sharePopularTJ',
      method: 'GET',
    })
  },
}


export default {

  communityApi
}
