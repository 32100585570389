<template>
  <div class="home" >
    <div v-if="info.id">
      <div class="header-wrap">
        <div class="head-right">

          <div class="head-img">
            <img :src="author.avatar ? author.avatar : require('../assets/avatar.jpg')" alt="" class="avatar">
          </div>
          <div class="head-info">
            <div class="head-name">{{author.nick_name}}</div>
            <div class="head-form">{{author.hospital_name}}</div>
          </div>
        </div>
        <div class="head-left">
          <!--        <div class="head-share">-->
          <!--          <van-icon name="share-o"/>-->
          <!--          <span>转发</span>-->
          <!--        </div>-->
<!--          <div class="head-good" @click="goodActive">-->
<!--            <van-icon name="good-job-o"  v-if="!info.is_praise" />-->
<!--            <van-icon name="good-job"  v-else :color="info.is_praise ? '#FFAB00':''"/>-->
<!--            <span>点赞{{info.praise_count}}</span>-->
<!--          </div>-->
        </div>
      </div>


      <div class="content" v-if="info.type === 'TEXT'">
        <div class="description">{{content.value}}</div>
        <div class="release-time">
          <div>
            <span>发布于：</span>
            <span>{{info.publish_time}}</span>
          </div>
          <div class="head-good" @click="goodActive">
            <van-icon name="good-job-o"  v-if="!info.is_praise" />
            <van-icon name="good-job"  v-else :color="info.is_praise ? '#FFAB00':''"/>
            <span>点赞{{info.praise_count}}</span>
          </div>
        </div>
      </div>
      <div class="content" v-if="info.type === 'IMAGE'">
        <div class="description">{{content.title}}</div>
        <div class="picture">
          <div class="picture-item" v-for="(item,index) in content.images" :key="index" @click="showImg(content.images,index)">
            <van-image class="picture-img" fit="cover" :src="item"/>
          </div>
        </div>
        <div class="release-time">
          <div>
            <span>发布于：</span>
            <span>{{info.publish_time}}</span>
          </div>
          <div class="head-good" @click="goodActive">
            <van-icon name="good-job-o"  v-if="!info.is_praise" />
            <van-icon name="good-job"  v-else :color="info.is_praise ? '#FFAB00':''"/>
            <span>点赞{{info.praise_count}}</span>
          </div>
        </div>
      </div>
      <div class="content" v-if="info.type === 'VIDEO'">
        <div class="description">{{content.title}}</div>
        <div class="video-wrap">
          <video
              id="myVideo"
              class="video"
              ref="myVideo"
              :src="content.url"
              object-fit="fill"
              :poster="content.cover_url"
              controls
          ></video>
        </div>
        <div class="release-time">
          <div>
            <span>发布于：</span>
            <span>{{info.publish_time}}</span>
          </div>
          <div class="head-good" @click="goodActive">
            <van-icon name="good-job-o"  v-if="!info.is_praise" />
            <van-icon name="good-job"  v-else :color="info.is_praise ? '#FFAB00':''"/>
            <span>点赞{{info.praise_count}}</span>
          </div>
        </div>
      </div>
      <div class="content" v-if="info.type === 'LINK'">
        <div class="description">{{content.title}}</div>
        <div class="link-wrap">
          <img :src="content.cover_url" alt="" class="link-img">
          <div class="link-txt"><a :href="content.link" target="_blank" class="profile">{{content.profile}}</a></div>
        </div>
        <div class="release-time">
          <div>
            <span>发布于：</span>
            <span>{{info.publish_time}}</span>
          </div>
          <div class="head-good" @click="goodActive">
            <van-icon name="good-job-o"  v-if="!info.is_praise" />
            <van-icon name="good-job"  v-else :color="info.is_praise ? '#FFAB00':''"/>
            <span>点赞{{info.praise_count}}</span>
          </div>
        </div>
      </div>

  <!--      <div class="give-wrap" v-if="praiseList.length > 0">-->
  <!--        <div class="give-head">-->
  <!--          <div class="">点赞</div>-->
  <!--          <div @click="showGive('down')" v-if="praiseList.length > 5 && showType === 'down'">展开-->
  <!--            <van-icon name="arrow-down"/>-->
  <!--          </div>-->
  <!--          <div @click="showGive('up')" v-if="praiseList.length > 5 && showType === 'up'">收起-->
  <!--            <van-icon name="arrow-up"/>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="give-content" v-show="giveShow">-->
  <!--          <div class="personnel-list">-->
  <!--            <div class="personnel-item" v-for="(item,index) in praiseShowList" :key="index">-->
  <!--              <div>-->
  <!--                <span class="praise-name">{{item.member.nick_name}}</span>-->
  <!--                <span class="praise-from">{{item.member.hospital_name}}</span>-->
  <!--              </div>-->
  <!--              <div class="praise-good">-->
  <!--                <van-icon name="good-job" color="#FFAB00"/>-->
  <!--                <span>点赞</span>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
      <div class="discuss-wrap" v-if="commentInfoList.length > 0">
        <div class="discuss-head">
          <div style="color: #333333">评论</div>
        </div>
        <div class="discuss-list">
          <div v-for="(item,index) in commentInfoList" :key="index">
            <div class="discuss-item">
              <div class="discuss-left">
                <img :src="item.member.avatar ? item.member.avatar : require('../assets/avatar.jpg')" alt="头像" class="discuss-img">
              </div>
              <div class="discuss-right">
                <div class="discuss-info">
                  <div class="discuss-name">{{item.member.nick_name}}</div>
                  <div class="discuss-time">{{item.created_time}}</div>
                </div>
                <div class="discuss-hospital_name">
                  <div class="hospital_name">{{item.member.hospital_name}}</div>
                </div>
                <div class="discuss-content">
                  <div class="discuss-txt">{{item.content}}</div>
                  <div class="reply-btn" v-if="logStatus" @click="replyFunc(item)">回复</div>
                </div>
              </div>
            </div>
            <div class="reply_comments" v-for="(rep) in item.reply_comments" :key="rep.id">
              <div class="reply-txt">
                <div class="reply_discuss-item">
                  <div class="reply_discuss-left">
                    <img :src="rep.member.avatar ? rep.member.avatar : require('../assets/avatar.jpg')" alt="头像" class="discuss-img">
                  </div>
                  <div class="reply_discuss-right">
                    <div class="reply_discuss-info">
                      <div class="reply_discuss-name">{{rep.member.nick_name}}</div>
                      <div class="reply_discuss-time">{{rep.created_time}}</div>
                    </div>
                    <div class="reply_discuss-hospital_name">
                      <div class="reply_hospital_name">{{rep.member.hospital_name}}</div>
                    </div>
                    <div class="reply_discuss-content" style="text-align: left">{{rep.content}}</div>
                  </div>
                </div>
<!--                <span class="reply-name">{{rep.member.nick_name}}:</span>-->
<!--                {{rep.content}}-->
              </div>
              <!--            <div class="reply-btn" @click="replyFunc(rep)">回复</div>-->
            </div>
          </div>
        </div>
      </div>

      <div class="comment-wrap" v-if="logStatus">
        <div class="comment-center">
          <div class="comment-content">
            <i class="reply-icon"></i>
            <input type="text" class="comment-input" placeholder="写评论..." v-model="comment">
            <span class="comment-btn" @click="addComment">发送</span>
          </div>
        </div>
      </div>

      <div class="vod-warp" v-if="hostList.length > 0">
        <div class="vod-discuss-head">
          <div style="color: #333333">热门推荐</div>
        </div>
        <div class="vod-list" v-for="hos in hostList" :key="hos.id" @click="openVod(hos)">
          <div style="width: 40%;position: relative">
            <div style="position: absolute;bottom: 5px;right: 0px;color: #333333;">
              <img style="width: 20px;height: 20px" src="../assets/stop.png"/>
            </div>
            <img class="vod-list-img" :src="hos.cover[0]"/>
          </div>
          <div class="vod-list-right">
            <div class="right-title">{{hos.title}}</div>

            <div style="position: absolute;bottom: 5px;width: 100%">

              <div>{{hos.expertName}}</div>

              <div class="right-bottom">
                <div>{{hos.expertHospital}}</div>
                <div><van-icon name="eye-o" /> {{hos.browseCount}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" :style="{ height: '30%'}" @close="popupClose">
      <van-form @submit="onSubmit" submit-on-enter>
        <div class="reply-wrap">
          <div class="message-lable">{{messageLabel}}</div>
          <van-button size="mini" type="primary" native-type="submit">提交</van-button>
        </div>

        <van-field
            v-model="message"
            rows="6"
            autofocus
            autosize
            type="textarea"
            placeholder="请输入"
            show-word-limit
        />

      </van-form>
    </van-popup>

    <div class="tips-wrap" v-if="!logStatus">
      <div class="tips-left">
        <div><img src="../assets/logo.png" alt="logo" class="tips-logo"></div>
        <div class="tips-content">
          <div>打开云ICU APP</div>
          <div>查看完整视频</div>
        </div>
      </div>
      <div class="tips-right">
        <button class="tips-btn" @click="goToShop">打开<van-icon name="arrow" /></button>
      </div>
    </div>

  </div>
</template>


<script>
  // eslint-disable-next-line no-unused-vars
  import {getQueryVariable} from "../libs/util";
  import {ImagePreview} from 'vant';

  export default {
    name: 'Home',
    components: {},
    data() {
      return {
        userInfo: {},
        content_id: '',
        isSelect: true,
        giveShow: true,
        info: {
          publish_time: '',
          praise_count: '',
          is_praise: ''
        },
        content: {},
        comment: '',
        message: '',
        author: {
          avatar:'',
          nick_name: '',
          hospital_name: '',
        },
        imgUrl: [

        ],
        personnelList: [

        ],
        commentInfoList: [

        ],
        hostList:[],
        praiseList: [],
        praisesize:5,
        show: false,
        messageLabel: '',
        replyId: '',
        logStatus:false,
        showType:'down'
      }
    },
    created() {

      // this.getQueryVariable()
      let token = getQueryVariable('token');
      let content_id = getQueryVariable('content_id');
      let title = getQueryVariable('title');
      let infoId = getQueryVariable('memberId');
      if(title){
        document.title = title
      }

      if(token){
          this.logStatus = true;
      }
      if(content_id){
          this.content_id = Number(content_id);
          this.communityDetail();
          this.getMyInfo();
          this.getPraiseList();
          this.getCommentList();
          if(!infoId){  //判断有没有memberId,有则不显示热门推荐
            this.getHotList();
          }
      }

    },
    computed:{
      praiseShowList(){
        let praiseList  = this.praiseList
        console.log(this.praiseList.length > 5)
        if(praiseList.length > 5){
          praiseList = praiseList.slice(0, this.praisesize)
        }
        return praiseList;

      }
    },
    methods: {

      getMyInfo() {
        this.$ajaxApi.communityApi.getMyInfo()
          .then(res => {
            if (res.data.data) {
              this.userInfo = res.data.data;
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      goodActive() {
        if(!this.logStatus){
          return
        }
        if (this.info.is_praise === 1) {
          return
        }
        let data = {
          content_id: this.content_id,
        }
        this.$ajaxApi.communityApi.addPraise(data)
          .then(res => {
            if (res.data.data) {
              this.$toast("点赞成功")
              this.communityDetail();
            } else {
              this.$toast(res.data.message)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      showImg(item, index) {
        ImagePreview({
          images: item,
          startPosition: index,
        })
      },
      showGive(type) {
        if(type === 'down'){
          this.showType = 'up';
          if(this.praiseList.length > 5){
            this.praisesize = this.praiseList.length + 1
          }
        }else{
          this.showType = 'down';
          this.praisesize = 5
        }
      },
      //获取详情
      updateMyInfo() {
        let data = {
          nick_name: '呦点蓝',
          avatar: 'https://himg.bdimg.com/sys/portrait/item/pp.1.2acb67ab.tpp8oDS9TuMoWFu8cUn6bw?_t=1629984128685',
        }
        this.$ajaxApi.communityApi.updateMyInfo(data)
      },
      //获取详情
      communityDetail() {
        let id = this.content_id
        this.$ajaxApi.communityApi.communityDetail(id)
          .then(res => {
            this.content = res.data.data.content;
            this.author = res.data.data.author;
            this.info = res.data.data;
          //  获取用户是否点赞
            this.praiseInfo(this.info.member_id,getQueryVariable('content_id'),this.info.type)
          })
          .catch(error => {
            console.log(error)
          })

      },
      praiseInfo(memberId,contentId,contentType) {
        this.$ajaxApi.communityApi.praiseInfo(memberId,contentId,contentType)
            .then(res => {
              this.$set(this.info,'is_praise',res.data.data.memberIsPraise ? 1:0)
              // this.info.is_praise = res.data.data.memberIsPraise ? 1:0
            })
            .catch(error => {
              console.log(error)
            })

      },
      //获取点赞
      getPraiseList() {
        let query = {
          content_id: this.content_id,
        }
        this.$ajaxApi.communityApi.getPraiseList(query)
          .then(res => {
            this.praiseList = res.data.data.rows
          })
          .catch(error => {
            console.log(error)
          })
      },
      //获取评论
      getCommentList() {
        let query = {
          content_id: this.content_id,
        }
        this.$ajaxApi.communityApi.getCommentList(query)
          .then(res => {
            this.commentInfoList = res.data.data.rows
          })
          .catch(error => {
            console.log(error)
          })
      },
      //获取热门推荐
      getHotList() {
        this.$ajaxApi.communityApi.hotList()
          .then(res => {
            this.hostList = res.data.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      //发表评论
      addComment() {
        if (this.comment === '') {
          this.$toast('请输入评论内容');
          return;
        }if (this.content_id === '') {
          this.$toast('获取content_id失败');
          return;
        }
        let data = {
          content: this.comment,
          content_id: Number(this.content_id),
        }
        this.$ajaxApi.communityApi.addComment(data)
          .then(res => {
            if (res.data.data) {
              this.$toast('评论成功');
              this.comment = '';
              this.getCommentList();
            } else {
              this.$toast(res.data.message)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      onSubmit() {
        if (this.message === '') {
          this.$toast('请输入评论内容');
          return;
        }
        let data = {
          content: this.message,
          comment_id: this.replyId,
        }
        this.$ajaxApi.communityApi.replyComment(data)
          .then(res => {
            if (res.data.data) {
              this.$toast('评论成功');
              this.message = '';
              this.show = false;
              this.getCommentList();
            } else {
              this.$toast(res.data.message)
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      replyFunc(item) {
        this.show = true;
        this.replyId = item.id;
        this.messageLabel = `回复@${item.member.nick_name}`
      },
      popupClose() {
        this.show = false;
        this.replyId = '';
      },

      goToShop(){

        let browser = {
          versions: function () {
            /*
            * navigator对象的属性
            * 浏览器代号: navigator.appCodeName
            * 浏览器名称: navigator.appName
            * 浏览器版本: navigator.appVersion
            * 启用Cookies: navigator.cookieEnabled
            * 硬件平台: navigator.platform
            * 用户代理: navigator.userAgent
            * 用户代理语言: navigator.systemLanguage
            */
            // eslint-disable-next-line no-unused-vars
            var u = navigator.userAgent, app = navigator.appVersion;
            return {
              //移动终端浏览器版本信息
              trident: u.indexOf('Trident') > -1, //IE内核
              presto: u.indexOf('Presto') > -1, //opera内核
              webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
              gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
              mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
              ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
              android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
              iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
              iPad: u.indexOf('iPad') > -1, //是否iPad
              webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
            };
          }(),
          language: (navigator.browserLanguage || navigator.language).toLowerCase()
        }

        if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {

          let url = 'https://apps.apple.com/cn/app/云icu/id1062118952'
          window.open(url);
        }
        else if (browser.versions.android) {
          window.open('https://a.app.qq.com/dom/micro/open.jsp?pkgname=com.ian.icu');
        }
      },
      openVod(row){
        console.log(1)
        window.open("https://tool-h5.yunicu.com/#/liveShare?id="+row.id+"&fileId="+row.fileId)
      }
    }

  }
</script>

<style scoped>
  .home {
    padding: 36px 0;
  }

  .header-wrap {
    display: flex;
    justify-content: space-between;
    padding: 0 13px;
  }

  .head-right {
    display: flex;
    align-items: center;
    padding: 0 40px 15px;
  }

  .head-img {
    width: 66px;
    height: 66px;
    background-color: #2c3e50;
    border-radius: 50%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;

  }

  .head-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 18px;
  }

  .head-name {
    font-size: 28px;
    color: #262626;
  }

  .head-form {
    font-size: 22px;
    color: #AFAFAF;
    padding-top: 9px;
  }

  .head-left, .head-share, .head-good {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .head-share > span, .head-good > span {
    font-size: 22px;
    color: #AFAFAF;
  }

  .head-good {
    margin-left: 39px;
  }

  .head-share > span, .head-good > span {
    padding-left: 12px;
  }


  .content {
    margin-top: 22px;
    text-align: left;
    padding: 0 55px 25px;
    /*border-bottom: 10px solid #F2F2F2;*/

  }

  .description {
    font-size: 26px;
    line-height: 34px;
    /*color: #4D5466;*/
    color: #333333;
    padding-bottom: 17px;
  }

  .picture {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .picture-img {
    width: 170px;
    height: 170px;
  }

  .release-time {
    padding-top: 18px;
    color: #AFAFAF;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
  }

  .give-wrap {
    padding: 0 73px;
    color: #73757F;
  }

  .give-head {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    padding: 29px 0 16px;
    border-bottom: 1px solid #E6E6E6;
  }

  .give-content {
    padding: 30px 0 0;
  }

  .personnel-item {
    padding-bottom: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .praise-name {
    font-size: 28px;
    color: #474747;
    font-weight: bold;
    padding-right: 15px;
  }

  .praise-from {
    color: #AFAFAF;
    font-size: 18px;
  }

  .praise-good {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .praise-good > span {
    color: #AFAFAF;
    font-size: 26px;
    padding-left: 12px;
  }

  .discuss-wrap {
    padding: 40px 20px 0;
    color: #73757F;
    font-size: 26px;
    border-top: 10px solid #F2F2F2;
    z-index: 999999999;
  }

  .discuss-head {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 16px;
  }

  .discuss-list {
    padding-top: 21px;
    overflow-y: scroll;
    padding-bottom: 100px;
    font-size: 26px;
  }

  .discuss-item {
    display: flex;
    /*align-items: center;*/
    font-size: 26px;
    padding-bottom: 15px;
  }

  .discuss-img {
    width: 66px;
    height: 66px;
    max-height: 66px;
    border-radius: 50%;
    font-size: 12px;
    /*background-color: aqua;*/
  }

  .discuss-left {
    width: 10%;
    margin-top: 20px;
    padding-right: 15px;
  }

  .discuss-right {
    width: 90%;
    text-align: center;
    padding-top: 15px;
    font-size: 26px;
  }

  .discuss-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
    font-size: 16px !important;
  }

  .reply_comments {
    /*background: #F4F4F4;*/
    border-radius: 15px;
    color: #292621;
    font-size: 18px;
    margin-left: 66px;
    display: flex;

  }

  .reply-txt {
    padding: 10px 18px;
    text-align: left;
    width: 100%;
  }
  .reply_discuss-item{
    display: flex;
  }
  .reply_discuss-left{
    width: 16%;
    margin-top: 20px;
  }
  .reply_discuss-right{
    width: 90%;
    text-align: center;
    padding-top: 15px;
  }
  .reply_discuss-info{
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
    font-size: 16px !important;
  }
  .reply_discuss-name {
    color: #333333;
    font-size: 16px !important;
    /*font-weight: bold;*/
  }
  .reply_discuss-time {
    font-size: 18px;
    color: #AFAFAF;
  }
  .reply_discuss-hospital_name{
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
  }
  .reply_hospital_name{
    color: #c1bebe;
    font-size: 12px;
  }
  .reply-name {
    color: #888888;
    font-size: 22px;
  }

  .discuss-txt {
    text-align: left;
    font-size: 23px;
    line-height: 32px;
    color: #888888;
  }

  .reply-icon {
    width: 36px;
    height: 36px;
    background: url("../assets/reply.png") no-repeat center center;
    background-size: cover;
    margin-left: 30px;
  }

  .discuss-name {
    color: #333333;
    font-size: 16px !important;
    /*font-weight: bold;*/
  }
  .discuss-hospital_name{
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
  }
  .hospital_name{
    color: #c1bebe;
    font-size: 12px;
  }
  .discuss-time {
    font-size: 18px;
    color: #AFAFAF;
  }

  .comment-wrap {
    width: 100%;
    background-color: #FFF;
    border-top: 1px solid #EEEFF0;
    position: fixed;
    bottom: 0;
    z-index: 99999999999999999999999999;
  }

  .comment-center {
    padding: 26px 30px 19px;

  }

  .comment-content {
    background-color: #EEEFF0;
    border-radius: 45px;
    padding: 10px 0;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .comment-input {
    border: none;
    background-color: #EEEFF0;
    height: 60px;
    line-height: 60px;
    width: 70%;
    font-size: 28px;
  }

  .comment-btn {
    color: #FFFFFF;
    background-color: #47A5E5;
    padding: 13px 26px;
    border-radius: 25px;
    font-size: 24px;
    transition: all 0.3;
  }

  .comment-btn:active, .comment-btn:hover {
    opacity: .58
  }

  .video-wrap {
    width: 100%;
  }

  .video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .link-wrap {
    display: flex;
    background-color: #EEEFF0;
    color: #4D5466;
  }

  .link-txt {
    padding: 14px 12px;
    flex: 1;
  }

  .profile {
    color: #4D5466;
    font-size: 22px;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .link-img {
    width: 80px;
    height: 80px;
  }

  .discuss-content {
    display: flex;
    justify-content: space-between;
  }

  .reply-btn {
    width: 80px;
    min-width: 60px;
    text-align: right;
    font-size: 18px;
    color: #73757F;
  }

  .reply_comments .reply-btn {
    padding: 3px;
  }
  .discuss-son{
    width: 10%;
    padding-right: 15px;
  }
  .reply-wrap {
    /*text-align: right;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
  }

  .message-lable {
    color: #888888;
  }

  .reply-wrap /deep/ .van-button {
    background: #FFFFFF;
    color: #47A5E5;
    border: 0;
  }

  .reply-wrap /deep/ .van-button__text {
    font-size: 32px;

  }

  .tips-wrap{
    width: 100%;
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999999;
  }
  .tips-logo{
    width: 80px;
    height: 80px;
  }
  .tips-left{
    display: flex;
    padding: 10px 30px;
  }

  .tips-content{
    color: #FFFFFF;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 18px;
    font-size: 24px;
  }
  .tips-right{
    padding-right: 30px;
  }
  .tips-btn{
    background: #45A5E6;
    color: #FFFFFF;
    padding: 16px 36px 16px 46px;
    border: 0;
    border-radius: 30px;
    display: flex;
    align-items: center;
  }
  .vod-warp {
    padding: 40px 20px 0;
    color: #73757F;
    border-top: 10px solid #F2F2F2;
    margin-bottom: 100px;
  }
  .vod-discuss-head{
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    padding-bottom: 16px;
    margin-bottom: 10px;
  }
  .vod-list{
    display: flex;
    border-top: 1px solid #eeeded;
    padding-top: 10px;min-height: 80px;
  }
  .vod-list-img{
    width: 288px;
    height: 162px;
    margin: auto;
    border-radius: 10px;
    border: 1px solid #f8f7f7;
  }
  .vod-list-right{
    width: 60%;
    color: #AFAFAF;
    font-size: 22px;
    text-align: left;
    padding: 20px;
    position: relative;
    z-index: 8;
  }
  .right-title{
    color: #333333;
    font-size: 26px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
    word-break: break-all;
  }
  .right-bottom{
    width: 99%;
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
  }
</style>

